<template>
  <div>
    <v-layout wrap justify-center py-16>
      <v-flex xs11 sm10>
        <v-layout wrap justify-space-between>
          <v-flex xs12 sm12 md8 text-left>
            <v-layout wrap>
              <v-flex xs12>
                <span
                  style="
                    color: #222222;
                    font-family: poppinsbold;
                    font-size: 25px;
                  "
                  >{{ blog.tittle }}</span
                >
              </v-flex>
              <v-flex xs12 pt-2>
                <v-icon small color="#555555">mdi-calendar</v-icon>
                <span
                  style="
                    color: #777777;
                    font-family: poppinsregular;
                    font-size: 12px;
                  "
                  >&nbsp;{{ formatDate(blog.create_date) }}</span
                >
              </v-flex>
              <v-flex xs12 pt-2>
                <v-img :src="mediaUURL + blog.image" style="border-radius: 2%">
                  <template v-slot:placeholder> <ImageLoader /> </template
                ></v-img>
              </v-flex>
              <v-flex xs12 pt-3>
                <span
                  style="
                    font-family: poppinsregular;
                    color: #666666;
                    font-size: 14px;
                  "
                  v-html="blog.content"
                  >
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md4 pl-xs-0 pl-sm-0 pl-md-16 pl-lg-16 pl-xl-16 pt-5>
            <v-layout wrap justify-end>
              <v-flex xs12>
                <v-card outlined rounded="lg" style="border-color: #000000">
                  <v-layout wrap pa-5>
                    <v-flex xs12 text-left>
                      <span
                        style="
                          color: #000000;
                          font-family: poppinsmedium;
                          font-size: 18px;
                        "
                        >Trending Blogs</span
                      >
                    </v-flex>
                    <v-flex xs12 pt-5>
                      <v-layout wrap pa-2 v-for="(item, i) in list" :key="i">
                        <v-flex xs6 sm6 md3>
                          <router-link :to="'/blogView?id=' + item._id">
                          <v-img
                            :src="mediaUURL + item.image"
                            style="border-radius: 5%"
                          >
                            <template v-slot:placeholder>
                              <ImageLoader /> </template
                          ></v-img>
                        </router-link>
                        </v-flex>
                        <v-flex xs6 sm6 md9 pl-5 text-left>
                          <v-layout wrap>
                            <v-flex xs12>
                              <span
                                style="
                                  color: #222222;
                                  font-family: poppinssemibold;
                                  font-size: 15px;
                                "
                                >{{ item.tittle }}</span
                              >
                            </v-flex>
                            <v-flex xs12>
                              <v-icon small color="#555555"
                                >mdi-calendar</v-icon
                              >
                              <span
                                style="
                                  color: #777777;
                                  font-family: poppinsregular;
                                  font-size: 12px;
                                "
                                >&nbsp;{{ formatDate(item.create_date) }}</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 text-left pt-3>
                      <router-link :to="'/blogList'">
                      <span
                        style="
                          color: #30b868;
                          font-family: poppinssemibold;
                          font-size: 14px;
                          text-decoration: underline;
                        "
                        >Read More stories→</span
                      >
                      </router-link>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 py-10>
            <v-divider color="#C4C4C4"></v-divider>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap>
              <v-flex
                xs12
                sm6
                md4
                pa-5
                v-for="(data, d) in list.slice(0, 3)"
                :key="d"
              >
                <v-layout wrap>
                  <v-flex xs12>
                    <router-link :to="'/blogView?id=' + data._id">
                    <v-img
                      :src="mediaUURL + data.image"
                      style="border-radius: 2%"
                    >
                      <template v-slot:placeholder> <ImageLoader /> </template
                    ></v-img>
                  </router-link>
                  </v-flex>
                  <v-flex xs12 text-left pt-3>
                    <span
                      style="
                        color: #222222;
                        font-family: poppinssemibold;
                        font-size: 18px;
                      "
                      >{{ data.tittle }}</span
                    >
                  </v-flex>
                  <v-flex xs12 text-left pt-2>
                    <v-icon small color="#555555">mdi-calendar</v-icon>
                    <span
                      style="
                        color: #777777;
                        font-family: poppinsregular;
                        font-size: 12px;
                      "
                      >&nbsp;{{ formatDate(data.create_date) }}</span
                    >
                  </v-flex>
                  <v-flex xs12 text-left pt-4>
                    <span
                      style="
                        color: #555555;
                        font-family: poppinsregular;
                        font-size: 13px;
                      "
                      v-html="data.content.slice(0, 150)"
                      ></span
                    >
                    <span v-if="data.content.length > 150"> ... </span>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      blog: {},
      list: [],
    };
  },
  beforeMount() {
    this.getData();
    this.getList();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/blogView",
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.blog = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/blog",
      })
        .then((response) => {
          this.appLoading = false;
          this.list = response.data.data;
          this.list=this.list.filter(x=>x._id!=this.$route.query.id)
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      //   var hours = dt.getHours();
      //   var minutes = dt.getMinutes();
      dt = dt.toString();
      //   var ampm = hours >= 12 ? "pm" : "am";
      //   hours = hours % 12;
      //   hours = hours ? hours : 12;
      //   minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
  },
};
</script>